import {
  FetchUsersDocument,
  FetchUsersQuery,
  useCancelFriendRequestMutation,
  UserByUsernameDocument,
  UserByUsernameQuery,
} from 'generated/graphql';
import { useMe } from 'hooks/useMe';
import { useRouter } from 'next/router';
import { FriendRequestPayload } from './types/friendRequestPayload';

export const useCancelFriendRequest = () => {
  const [cancelFriendRequestFunc] = useCancelFriendRequestMutation();
  const me = useMe();

  const router = useRouter();

  return (user: FriendRequestPayload) => {
    return cancelFriendRequestFunc({
      variables: {
        targetId: parseInt(user.id, 10),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        cancelFriendRequest: {
          ...user,
          haveISentFriendRequest: false,
          haveIReceivedFriendRequest: false,
        },
      },
      update(store, fetchResult) {
        const cancelFriendRequest = fetchResult?.data?.cancelFriendRequest;

        if (!cancelFriendRequest) {
          return;
        }

        try {
          const variables = { filter: router.query.q };

          const data = store.readQuery<FetchUsersQuery>({
            query: FetchUsersDocument,
            variables,
          });

          if (data?.users) {
            data.users.edges.forEach(({ node }) => {
              if (String(node.id) === String(user.id)) {
                node.haveISentFriendRequest = false;
              }
            });
          }

          store.writeQuery({
            query: FetchUsersDocument,
            data,
            variables,
          });
        } catch (error) {
          console.error(error);
        }

        try {
          const user1 = store.readQuery<UserByUsernameQuery>({
            query: UserByUsernameDocument,
            variables: { username: user.username },
          });

          if (user1?.userByUsername) {
            user1.userByUsername.haveIReceivedFriendRequest = false;
            user1.userByUsername.haveISentFriendRequest = false;
          }

          store.writeQuery({ query: UserByUsernameDocument, data: user1, variables: { username: user.username } });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }

        store.modify({
          id: `User:${me.me!.id}`,
          fields: {
            getFriendInvites: (existing, { readField }) => {
              return existing.filter(node => readField('id', node) != user.id);
            },
          },
        });
      },
    });
  };
};
