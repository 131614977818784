import styled, { css } from 'styled-components';
import { CirclePlaceholder, LoadingCard as BaseLoadingCard } from 'components/common/SkeletonLoader';

export const UserCard = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.dark};
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  flex: 1;
  width: 204px;
  pointer-events: painted;
`;

const ProfilePictureSize = css`
  width: 100px;
  height: 100px;
  border-radius: 50%;

  @media (max-width: 1024px) {
    width: 85px;
    height: 85px;
  }
  @media (max-width: 1200px) {
    width: 75px;
    height: 75px;
  }
`;

export const ProfilePicture = styled.img`
  ${ProfilePictureSize}
`;
export const FriendSince = styled.span`
  color: ${({ theme }) => theme.primaryColor.mediumGray};
  font-size: 0.75rem;
`;

export const Username = styled.span<{ small?: boolean }>`
  word-break: break-all;
  color: ${({ theme }) => theme.primaryColor.white};
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
    font-size: 18px;
  }
`;

export const IsFriends = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${({ theme }) => theme.primaryColor.green};
`;

export const UserCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 1rem;
  @media screen and (max-width: ${props => props.theme.sass.break_medium}) {
    margin-bottom: 1rem;
  }
  gap: 1rem;
`;
export const FriendsText = styled.p`
  color: ${({ theme }) => theme.primaryColor.green};
  font-size: 1rem;
  margin: 0;
`;
export const UserWrapper = styled.a`
  display: flex;
  cursor: pointer;
`;

export const LoadingCard = styled(BaseLoadingCard)`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoaderCircle = styled(CirclePlaceholder)`
  ${ProfilePictureSize}
`;
