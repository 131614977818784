import { Checkmark, Friends } from '@hiberworld/icons';
import { FlexBox } from 'components/layouts/FlexBox';
import { FriendRequestButton } from 'components/common/buttons/FriendButton';
import { FriendRequestAction } from 'components/common/buttons/FriendButton/FriendButton.types';
import { Link } from 'components/common/Link';
import { Spacer } from 'components/layouts/Spacer';
import { UserLevel } from 'components/legacy/UserLevel';
import { VerifiedBadge } from 'components/globals/VerifiedBadge';
import { FriendRequestPayload } from 'hooks/friends/types/friendRequestPayload';
import { useAcceptFriendRequest } from 'hooks/friends/useAcceptFriendRequest';
import { useCancelFriendRequest } from 'hooks/friends/useCancelFriendRequest';
import { useSendFriendRequest } from 'hooks/friends/useSendFriendRequest';
import { useMe } from 'hooks/useMe';
import dynamic from 'next/dynamic';
import React from 'react';
import { useTheme } from 'styled-components';
import * as S from './UserCard.styles';
import { UserCardProps } from './UserCard.types';
import { Button } from 'components/common/buttons/Button';

const RelativeDate = dynamic(() => import('components/common/RelativeDate'), { ssr: false });

export const UserCard: React.FC<UserCardProps> = ({
  user,
  friendSince,
  followerSince,
  followingSince,
  hideCheck,
  hideFriendRequestButton,
  showOnlyViewRequest,
}) => {
  const {
    username,
    pictureUrl,
    level,
    isVerified,
    isEmployee,
    haveISentFriendRequest,
    haveIReceivedFriendRequest,
    isFriends,
  } = user;
  const theme = useTheme();

  const cancelFriendRequest = useCancelFriendRequest();
  const sendFriendRequest = useSendFriendRequest();
  const acceptFriendRequest = useAcceptFriendRequest();
  const { me, isLoggedIn } = useMe();
  const isMe = me?.id === user.id;
  const showFriendRequestButton = !isMe && !isFriends && !hideFriendRequestButton;

  const handleFriendRequest = (action: FriendRequestAction, friendRequest: FriendRequestPayload) => {
    switch (action) {
      case FriendRequestAction.SEND_REQUEST:
        sendFriendRequest(friendRequest);
        break;
      case FriendRequestAction.CANCEL_REQUEST:
        cancelFriendRequest(friendRequest);
        break;

      case FriendRequestAction.ACCEPT_REQUEST:
        acceptFriendRequest(user);
        break;

      default:
        console.error('No FriendRequestAction specified in handleFriendRequest()');
        break;
    }
  };
  return (
    <Link href={`/user/${username}`} passHref legacyBehavior prefetch={false}>
      <S.UserWrapper data-testid="user-card-link">
        <S.UserCard>
          <FlexBox justifyContent="center" position="relative">
            <S.Username>
              {username}
              <VerifiedBadge isEmployee={isEmployee} isVerified={isVerified} size={18} />
            </S.Username>
          </FlexBox>
          <FlexBox alignItems="center" justifyContent="center">
            <FlexBox position="relative" direction="column">
              <S.ProfilePicture src={pictureUrl || ''} title={`${username} profile image`} />
              <UserLevel level={level} top="67%" />
            </FlexBox>
          </FlexBox>
          <Spacer height={16} />
          <FlexBox direction="column" alignItems="center" textAlign="center">
            {isFriends && !hideCheck && (
              <FlexBox alignItems="center" height="44px">
                <S.FriendsText>Friends</S.FriendsText>
                <Spacer width={4} />
                <Checkmark title="Check" color={theme.primaryColor.green} size={20} />
              </FlexBox>
            )}
            {friendSince && (
              <S.FriendSince>
                Friends since&nbsp;
                <RelativeDate value={friendSince} />
              </S.FriendSince>
            )}
            {followingSince && (
              <S.FriendSince>
                Followed since&nbsp;
                <RelativeDate value={followingSince} />
              </S.FriendSince>
            )}
            {followerSince && (
              <S.FriendSince>
                Follower since&nbsp;
                <RelativeDate value={followerSince} />
              </S.FriendSince>
            )}
          </FlexBox>
          {showFriendRequestButton && isLoggedIn && (
            <FlexBox justifyContent="center">
              {showOnlyViewRequest && Boolean(haveIReceivedFriendRequest) ? (
                <Button variety="primary" size="medium" text="View Request" Icon={<Friends size={20} />} />
              ) : (
                <>
                  <FriendRequestButton
                    haveISentFriendRequest={Boolean(haveISentFriendRequest)}
                    haveIReceivedFriendRequest={Boolean(haveIReceivedFriendRequest)}
                    friendRequest={user}
                    onClick={handleFriendRequest}
                  />
                  {haveIReceivedFriendRequest && (
                    <FriendRequestButton
                      haveISentFriendRequest={false}
                      haveIReceivedFriendRequest
                      friendRequest={user}
                      declineFriendRequest
                      onClick={handleFriendRequest}
                      simpleDecline
                    />
                  )}
                </>
              )}
            </FlexBox>
          )}
        </S.UserCard>
      </S.UserWrapper>
    </Link>
  );
};

export default UserCard;
