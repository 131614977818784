import { useSendFriendRequestMutation } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useRouter } from 'next/router';
import { FriendRequestPayload } from './types/friendRequestPayload';

export const useSendFriendRequest = () => {
  const [sendFriendRequestFunc] = useSendFriendRequestMutation();

  const dive = useDive();
  const router = useRouter();

  return (user: FriendRequestPayload) => {
    dive.trackUserFriendRequestSent(user.username, router.asPath);
    return sendFriendRequestFunc({
      variables: {
        userId: parseInt(user.id, 10),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        sendFriendRequest: {
          __typename: 'User',
          id: user.id,
          haveIReceivedFriendRequest: false,
          haveISentFriendRequest: true,
        },
      },
    });
  };
};
