import styled from 'styled-components';

type UserLevelStyleProps = {
  top?: string;
  left?: string;
};
type UserLevelProps = {
  level?: number | null;
} & UserLevelStyleProps;

const UserLevelComponentWrapper = styled.div<UserLevelStyleProps>`
  position: absolute;
  left: ${({ left }) => left ?? '75%'};
  top: ${({ top }) => top ?? '45%'};
  font-size: 0.7rem;
  color: ${({ theme }) => theme.color.white.primary};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colorTheme.accents.purple.primary};
  text-align: center;
  line-height: 25px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.3rem;
`;

export const UserLevel = ({ level, left, top }: UserLevelProps) => (
  <UserLevelComponentWrapper left={left} top={top} aria-label="user-level">
    {level}
  </UserLevelComponentWrapper>
);
